import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    attrs: {
      "id": "hvwlink"
    }
  }, [_vm.currentHvData.vnaddress && _vm.currentHvData.address ? _c('div', {
    staticClass: "overflower"
  }, [_c('h4', [_c('div', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.currentHvData.address.street))]), _c('p', [_vm._v(_vm._s(_vm.currentHvData.address.plz) + " " + _vm._s(_vm.currentHvData.address.city))])]), _c('p', [_vm._v("VN: " + _vm._s(_vm.currentHvData.vnaddress.name))])]) : _vm._e(), _vm.isLoading ? _c('div', {
    staticClass: "overflower tac"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })]) : _vm._e(), _vm.isErrorOnSave ? _c('div', {
    staticClass: "overflower"
  }, [_vm.isDataNotSaved ? _c('span', [_c('h1', {
    staticClass: "pad"
  }, [_vm._v("Daten wurden NICHT gespeichert")]), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Dieses Dokument wurde bereits für die Bearbeitung gesperrt.")]), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Bei Fragen oder technischen Störungen kontaktieren Sie bitte Herrn Matthias Lex unter der Telefonnummer +43 2704104 313 oder per Email office@consense.at.")])]) : _c('span', [_c('h1', {
    staticClass: "pad"
  }, [_vm._v("Keine Daten vorhanden!")]), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Dieses Dokument existiert nicht oder wurde bereits für die Bearbeitung gesperrt.")]), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Bei Fragen oder technischen Störungen kontaktieren Sie bitte Herrn Matthias Lex unter der Telefonnummer +43 2704104 313 oder per Email office@consense.at.")])])]) : !_vm.isLoading && !_vm.isErrorOnSave && _vm.DataSaved ? _c('div', {
    staticClass: "overflower"
  }, [_c('h4', [_vm._v("Daten gespeichert")]), _c('h1', {
    staticClass: "pad"
  }, [_vm._v("Vielen Dank für Ihre Unterstützung")]), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Mit freundlichen Grüßen, ")]), _c('div', {
    staticClass: "bold pad"
  }, [_vm._v("Consense GmbH")])]) : !_vm.isLoading && !_vm.isErrorOnSave && !_vm.DataSaved ? _c('div', {
    staticClass: "overflower"
  }, [_vm._m(0), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Bitte füllen Sie die untenstehenden Fragen aus.")]), _c('p', {
    staticClass: "pad"
  }, [_vm._v("Bei Fragen oder technischen Störungen kontaktieren Sie bitte Herrn Matthias Lex unter der Telefonnummer +43 2704104 313 oder per Email office@consense.at.")]), _vm._m(1), !_vm.isLoading && _vm.currentHvData && _vm.currentHvData.address ? _c('ul', {
    staticClass: "hutable"
  }, [_c('li', [_vm._m(2), _c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Ist die angeführte Adresse korrekt und vollständig? Falls nein, bitte geben Sie die korrekte und vollständige Adresse ein. ")]), _c('div', {
    staticClass: "padding"
  }, [_c('div', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.currentHvData.address.land))]), _c('div', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.currentHvData.address.plz) + " " + _vm._s(_vm.currentHvData.address.city))]), _c('div', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.currentHvData.address.street))])]), _c('div', {
    staticClass: "padding"
  }, [_c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "JA"
    },
    model: {
      value: _vm.currentHvData.address.correctaddress,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.address, "correctaddress", $$v);
      },
      expression: "currentHvData.address.correctaddress"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "KEINE ANGABE"
    },
    model: {
      value: _vm.currentHvData.address.correctaddress,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.address, "correctaddress", $$v);
      },
      expression: "currentHvData.address.correctaddress"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "NEIN"
    },
    model: {
      value: _vm.currentHvData.address.correctaddress,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.address, "correctaddress", $$v);
      },
      expression: "currentHvData.address.correctaddress"
    }
  })], 1), _vm.currentHvData.address.correctaddress == 'NEIN' ? _c('div', [_c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Bitte hier die korrekte und vollständige Adresse eingeben:")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Straße + Hausnummer")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHvData.address.changed.street,
      expression: "currentHvData.address.changed.street"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHvData.address.changed.street
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData.address.changed, "street", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("PLZ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHvData.address.changed.plz,
      expression: "currentHvData.address.changed.plz"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHvData.address.changed.plz
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData.address.changed, "plz", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Stadt")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHvData.address.changed.city,
      expression: "currentHvData.address.changed.city"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHvData.address.changed.city
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData.address.changed, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Land")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHvData.address.changed.land,
      expression: "currentHvData.address.changed.land"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHvData.address.changed.land
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData.address.changed, "land", $event.target.value);
      }
    }
  })])]) : _vm._e()]), _c('li', [_vm._m(3), _c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Kann man die Eingangstür des Objekts mittels Zentral-Schlüssel öffnen? Falls nein, kann der Sachverständige die Tür anderweitig ohne Schlüssel öffnen?")]), _c('div', {
    staticClass: "padding"
  }, [_c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "JA"
    },
    model: {
      value: _vm.currentHvData.zentral.iskey,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.zentral, "iskey", $$v);
      },
      expression: "currentHvData.zentral.iskey"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "KEINE ANGABE"
    },
    model: {
      value: _vm.currentHvData.zentral.iskey,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.zentral, "iskey", $$v);
      },
      expression: "currentHvData.zentral.iskey"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "NEIN"
    },
    model: {
      value: _vm.currentHvData.zentral.iskey,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.zentral, "iskey", $$v);
      },
      expression: "currentHvData.zentral.iskey"
    }
  })], 1), _vm.currentHvData.zentral.iskey == 'NEIN' ? _c('div', {
    staticClass: "altern"
  }, [_c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Bitte hier eine Möglichkeit angeben wenn vorhanden:")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Alternative Möglichkeit")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.currentHvData.zentral.alternative,
      expression: "currentHvData.zentral.alternative",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHvData.zentral.alternative
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData.zentral, "alternative", _vm._n($event.target.value));
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]) : _vm._e()]), _c('li', [_vm._m(4), _c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Um was für ein Objekt (Objektart) handelt es sich?")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Objektart")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.vals['gebaeudeart'],
      "reduce": function reduce(itm) {
        return itm.name;
      }
    },
    model: {
      value: _vm.currentHvData.objektart,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData, "objektart", $$v);
      },
      expression: "currentHvData.objektart"
    }
  })], 1)])]), _c('li', [_vm._m(5), _c('p', {
    staticClass: "instrc"
  }), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Wie viele Wohneinheiten befinden sich im Objekt")]), _vm._v("Wenn nicht genau bekannt, bitte einen ungefähren Wert angeben."), _c('p'), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Wohneinheiten")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.vals['wohneinheiten'],
      "reduce": function reduce(itm) {
        return itm.name;
      }
    },
    model: {
      value: _vm.currentHvData.wohneinheiten,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData, "wohneinheiten", $$v);
      },
      expression: "currentHvData.wohneinheiten"
    }
  })], 1)])]), _c('li', [_vm._m(6), _c('p', {
    staticClass: "instrc"
  }), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Wann wurde das Objekt errichtet?")]), _vm._v("Wenn nicht genau bekannt, bitte ungefähres Jahr angeben."), _c('p'), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Baujahr")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.vals['baujahr'],
      "reduce": function reduce(itm) {
        return itm.name;
      }
    },
    model: {
      value: _vm.currentHvData.baujahr,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData, "baujahr", $$v);
      },
      expression: "currentHvData.baujahr"
    }
  })], 1)])]), _c('li', [_vm._m(7), _c('p', {
    staticClass: "instrc"
  }), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Wann wurde das Objekt zuletzt renoviert?")]), _vm._v("Wenn nicht genau bekannt, bitte einen ungefähren Wert angeben."), _c('p'), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Renorvier Jahr")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.vals['baujahr'],
      "reduce": function reduce(itm) {
        return itm.name;
      }
    },
    model: {
      value: _vm.currentHvData.renovierung,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData, "renovierung", $$v);
      },
      expression: "currentHvData.renovierung"
    }
  })], 1)])]), _c('li', [_vm._m(8), _vm._m(9), _c('div', {
    staticClass: "padding"
  }, [_c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "JA"
    },
    model: {
      value: _vm.currentHvData.denkmal,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData, "denkmal", $$v);
      },
      expression: "currentHvData.denkmal"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "KEINE ANGABE"
    },
    model: {
      value: _vm.currentHvData.denkmal,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData, "denkmal", $$v);
      },
      expression: "currentHvData.denkmal"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "NEIN"
    },
    model: {
      value: _vm.currentHvData.denkmal,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData, "denkmal", $$v);
      },
      expression: "currentHvData.denkmal"
    }
  })], 1)]), _c('li', [_vm._m(10), _c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Wird das Objekt auch gewerblich genutzt? Falls ja, wie viele Prozent der Fläche umfasst die gewerbliche Nutzung? Falls ja, bitte die Nutzungsart ebenso angeben:")]), _c('div', {
    staticClass: "padding"
  }, [_c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "JA"
    },
    model: {
      value: _vm.currentHvData.gewerbe.isgewerbe,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.gewerbe, "isgewerbe", $$v);
      },
      expression: "currentHvData.gewerbe.isgewerbe"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "KEINE ANGABE"
    },
    model: {
      value: _vm.currentHvData.gewerbe.isgewerbe,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.gewerbe, "isgewerbe", $$v);
      },
      expression: "currentHvData.gewerbe.isgewerbe"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "NEIN"
    },
    model: {
      value: _vm.currentHvData.gewerbe.isgewerbe,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.gewerbe, "isgewerbe", $$v);
      },
      expression: "currentHvData.gewerbe.isgewerbe"
    }
  }), _vm.currentHvData.gewerbe.isgewerbe == 'JA' ? _c('span', [_c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Gewerbefläche Anteil in %:")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.vals['percent'],
      "reduce": function reduce(itm) {
        return itm.name;
      }
    },
    model: {
      value: _vm.currentHvData.gewerbe.area,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.gewerbe, "area", $$v);
      },
      expression: "currentHvData.gewerbe.area"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Nutzungsart:")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.vals['betriebsart'],
      "reduce": function reduce(itm) {
        return itm.name;
      }
    },
    model: {
      value: _vm.currentHvData.gewerbe.nutzungsart,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.gewerbe, "nutzungsart", $$v);
      },
      expression: "currentHvData.gewerbe.nutzungsart"
    }
  })], 1)])]) : _vm._e()], 1)]), _c('li', [_vm._m(11), _c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Ist der Name und die Adresse vom Versicherungsnehmer des Objektes korrekt?")]), _c('div', {
    staticClass: "padding"
  }, [_c('div', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.currentHvData.vnaddress.name))]), _c('div', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.currentHvData.vnaddress.land))]), _c('div', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.currentHvData.vnaddress.plz) + " " + _vm._s(_vm.currentHvData.vnaddress.city))]), _c('div', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.currentHvData.vnaddress.street))])]), _c('div', {
    staticClass: "padding"
  }, [_c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "JA"
    },
    model: {
      value: _vm.currentHvData.vnaddress.correctaddress,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.vnaddress, "correctaddress", $$v);
      },
      expression: "currentHvData.vnaddress.correctaddress"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "KEINE ANGABE"
    },
    model: {
      value: _vm.currentHvData.vnaddress.correctaddress,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.vnaddress, "correctaddress", $$v);
      },
      expression: "currentHvData.vnaddress.correctaddress"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "NEIN"
    },
    model: {
      value: _vm.currentHvData.vnaddress.correctaddress,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.vnaddress, "correctaddress", $$v);
      },
      expression: "currentHvData.vnaddress.correctaddress"
    }
  })], 1), _vm.currentHvData.vnaddress.correctaddress == 'NEIN' ? _c('span', [_c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Bitte hier die korrekte und vollständige Adresse und den korrekten Namen eingeben:")]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Versicherungsnehmer")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHvData.vnaddress.changed.name,
      expression: "currentHvData.vnaddress.changed.name"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHvData.vnaddress.changed.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData.vnaddress.changed, "name", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Straße und Hausnummer")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHvData.vnaddress.changed.street,
      expression: "currentHvData.vnaddress.changed.street"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHvData.vnaddress.changed.street
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData.vnaddress.changed, "street", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("PLZ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHvData.vnaddress.changed.plz,
      expression: "currentHvData.vnaddress.changed.plz"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHvData.vnaddress.changed.plz
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData.vnaddress.changed, "plz", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Stadt")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHvData.vnaddress.changed.city,
      expression: "currentHvData.vnaddress.changed.city"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHvData.vnaddress.changed.city
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData.vnaddress.changed, "city", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Land")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHvData.vnaddress.changed.land,
      expression: "currentHvData.vnaddress.changed.land"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.currentHvData.vnaddress.changed.land
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData.vnaddress.changed, "land", $event.target.value);
      }
    }
  })])]) : _vm._e()]), _c('li', [_vm._m(12), _c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Auf welche Art wird das Objekt beheizt?")]), _vm._l(_vm.vals['heizungsanlage'], function (v, i) {
    return _c('label', {
      key: v.name + 'obl' + i,
      staticClass: "radio-field",
      class: {
        _sel: _vm.$_hasInArray(_vm.currentHvData.heizungsanlage, v.name)
      },
      attrs: {
        "for": v.name + 'obl' + i
      }
    }, [_vm.$_hasInArray(_vm.currentHvData.heizungsanlage, v.name) ? _c('i', {
      staticClass: "icon-check"
    }) : !_vm.$_hasInArray(_vm.currentHvData.heizungsanlage, v.name) ? _c('i', {
      staticClass: "icon-circle"
    }) : _vm._e(), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.currentHvData.heizungsanlage,
        expression: "currentHvData.heizungsanlage"
      }],
      staticClass: "check",
      attrs: {
        "type": "checkbox",
        "id": v.name + 'obl' + i
      },
      domProps: {
        "value": v.name,
        "checked": Array.isArray(_vm.currentHvData.heizungsanlage) ? _vm._i(_vm.currentHvData.heizungsanlage, v.name) > -1 : _vm.currentHvData.heizungsanlage
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.currentHvData.heizungsanlage,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = v.name,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.currentHvData, "heizungsanlage", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.currentHvData, "heizungsanlage", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.currentHvData, "heizungsanlage", $$c);
          }
        }
      }
    }), _c('span', [_vm._v(" " + _vm._s(v.name))])]);
  })], 2), _c('li', [_vm._m(13), _c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Verfügt das Objekt über sonstige technische Anlagen? (mehrfache Nennung möglich)")]), _vm._l(_vm.vals['haustechnik'], function (v, i) {
    return _c('label', {
      key: v.name + 'anl' + i,
      staticClass: "radio-field",
      class: {
        _sel: _vm.$_hasInArray(_vm.currentHvData.haustechnik, v.name)
      },
      attrs: {
        "for": v.name + 'anl' + i
      }
    }, [_vm.$_hasInArray(_vm.currentHvData.haustechnik, v.name) ? _c('i', {
      staticClass: "icon-check"
    }) : !_vm.$_hasInArray(_vm.currentHvData.haustechnik, v.name) ? _c('i', {
      staticClass: "icon-circle"
    }) : _vm._e(), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.currentHvData.haustechnik,
        expression: "currentHvData.haustechnik"
      }],
      staticClass: "check",
      attrs: {
        "type": "checkbox",
        "id": v.name + 'anl' + i
      },
      domProps: {
        "value": v.name,
        "checked": Array.isArray(_vm.currentHvData.haustechnik) ? _vm._i(_vm.currentHvData.haustechnik, v.name) > -1 : _vm.currentHvData.haustechnik
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.currentHvData.haustechnik,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = v.name,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.currentHvData, "haustechnik", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.currentHvData, "haustechnik", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.currentHvData, "haustechnik", $$c);
          }
        }
      }
    }), _c('span', [_vm._v(" " + _vm._s(v.name))])]);
  })], 2), _c('li', [_vm._m(14), _c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Befinden sich auf dem Grundstück Nebengebäude und/oder Außenanlagen? (mehrfache Nennung möglich)")]), _vm._l(_vm.vals['aussen'], function (v) {
    return _c('label', {
      key: v.name,
      staticClass: "radio-field",
      class: {
        _sel: _vm.$_hasInArray(_vm.currentHvData.aussenanlagen, v.name)
      },
      attrs: {
        "for": v.name
      }
    }, [_vm.$_hasInArray(_vm.currentHvData.aussenanlagen, v.name) ? _c('i', {
      staticClass: "icon-check"
    }) : !_vm.$_hasInArray(_vm.currentHvData.aussenanlagen, v.name) ? _c('i', {
      staticClass: "icon-circle"
    }) : _vm._e(), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.currentHvData.aussenanlagen,
        expression: "currentHvData.aussenanlagen"
      }],
      staticClass: "check",
      attrs: {
        "type": "checkbox",
        "id": v.name
      },
      domProps: {
        "value": v.name,
        "checked": Array.isArray(_vm.currentHvData.aussenanlagen) ? _vm._i(_vm.currentHvData.aussenanlagen, v.name) > -1 : _vm.currentHvData.aussenanlagen
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.currentHvData.aussenanlagen,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = v.name,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.currentHvData, "aussenanlagen", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.currentHvData, "aussenanlagen", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.currentHvData, "aussenanlagen", $$c);
          }
        }
      }
    }), _c('span', [_vm._v(" " + _vm._s(v.name))])]);
  })], 2), _c('li', [_vm._m(15), _c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Wurde der Dachboden ausgebaut? Wenn ja, wann?")]), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "JA"
    },
    model: {
      value: _vm.currentHvData.dachbodenausbau.is,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.dachbodenausbau, "is", $$v);
      },
      expression: "currentHvData.dachbodenausbau.is"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "KEINE ANGABE"
    },
    model: {
      value: _vm.currentHvData.dachbodenausbau.is,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.dachbodenausbau, "is", $$v);
      },
      expression: "currentHvData.dachbodenausbau.is"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": "NEIN"
    },
    model: {
      value: _vm.currentHvData.dachbodenausbau.is,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.dachbodenausbau, "is", $$v);
      },
      expression: "currentHvData.dachbodenausbau.is"
    }
  }), _vm.currentHvData.dachbodenausbau.is == 'JA' ? _c('span', [_c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Wann wurde der Dachboden ausgebaut:")]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Dachbodenausbau Jahr:")]), _c('div', {
    staticClass: "input"
  }, [_c('VueSelect', {
    attrs: {
      "label": "name",
      "options": _vm.vals['dachbodenausbau'],
      "reduce": function reduce(itm) {
        return itm.name;
      }
    },
    model: {
      value: _vm.currentHvData.dachbodenausbau.when,
      callback: function callback($$v) {
        _vm.$set(_vm.currentHvData.dachbodenausbau, "when", $$v);
      },
      expression: "currentHvData.dachbodenausbau.when"
    }
  })], 1)])]) : _vm._e()], 1), _c('li', [_vm._m(16), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Sonstige Info an CONSENSE GmbH")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentHvData.sonstiges,
      expression: "currentHvData.sonstiges"
    }],
    staticClass: "textarea",
    domProps: {
      "value": _vm.currentHvData.sonstiges
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.currentHvData, "sonstiges", $event.target.value);
      }
    }
  })]), _c('li', [_c('div', {
    staticClass: "btn _large",
    on: {
      "click": _vm.SaveAktHVW
    }
  }, [_vm._v("Speichern")])]), _c('div', {
    staticClass: "spacer _vertical _large"
  }), _c('div', {
    staticClass: "spacer _vertical _large"
  })]) : _vm._e()]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h1', {
    staticClass: "pad"
  }, [_vm._v("FRAGEBOGEN ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('p', {
    staticClass: "pad"
  }, [_vm._v("Sollten sich mehrere Gebäude auf dem Grundstück befinden und sich weitestgehend ähneln, dann bitte nur für ein Gebäude diesen Fragenbogen ausfüllen. und es unten unter \"Sonstiges\" vermerken."), _c('br'), _vm._v("Wenn jedoch sich die Gebäude grundlegend unterscheiden, dann füllen Sie den Fragebogen nur für ein representatives Gebäude aus und vermerken Sie dies ebenfalls unter \"Sonstiges\".")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("1. Adresse")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("2. Eingangstür")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("3. Objektart")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("4. Wohneinheiten")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("5. Errichtungsdatum")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("6. Renovierdatum")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions impred"
  }, [_c('h2', [_vm._v("7. Denkmalschutz")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('p', {
    staticClass: "instrc bold"
  }, [_vm._v("Steht das Objekt unter Denkmalschutz?"), _c('br'), _vm._v("Diese Information ist uns besonders wichtig!")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("8. Gewerbe")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("9. Versicherungsnehmer")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("10. Heizart")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("11. Technische Anlagen")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("12. Außenanlage")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("13. Dachboden")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "blockfunctions"
  }, [_c('h2', [_vm._v("14. Sonstiges")])]);
}];
export { render, staticRenderFns };